import { defineComponent } from 'vue';
export default defineComponent({
    name: 'IndividualUsage',
    setup() {
        const techSupportEmail_AU = 'qhomewarranty@qcells.com.au';
        return {
            techSupportEmail_AU,
        };
    },
});
